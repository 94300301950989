<style lang="scss" scoped>
.ui-left {
  width: 360px;
  position: absolute;
  top: 109px;
  left: 20px;
  z-index: 4;
  img {
    width: 100%;
  }

}
.b1{

    right: 100px;
    top:50px; 
}

.b2{

    right: 100px;
    top:90px; 
}

.b3{

    right: 100px;
    top:130px; 
}


.b5{

    right: 100px;
    top:240px; 
}

.b6{

    right: 100px;
    top:280px; 
}

.b7{

    right: 100px;
    top:320px; 
}

.b8{

    right: 100px;
    top:430px; 
}

.b9{

    right: 100px;
    top:470px; 
}

.b10{

    right: 100px;
    top:505px; 
}

.b11{

    right: 100px;
    top:615px; 
}


.b12{

  right: 100px;
    top:655px; 
}

.b13{

  right: 100px;
    top:695px; 
}

.b14{

  right: 100px;
    top:800px; 
}

.b15{

  right: 100px;
    top:840px; 
}

.b16{

  right: 100px;
    top:880px; 
}
</style>

<template>
  <div class="index">
    <div class="ui-left" v-rc:left>
      <img src="./ui-left.png" alt="" />
      <div class="nb b1" @click="show('bianpeidian','normal')"></div>
      <div class="nb b2" @click="showtotat"></div>
      <div class="nb b3" @click="show('bianpeidian','warning')"></div>
      
      <div class="nb b5" @click="show('zhaoming','normal')"></div>
      <div class="nb b6" @click="showtotat"></div>
      <div class="nb b7" @click="showtotat"></div>


       <div class="nb b8" @click="show('dianzitinchepai','normal')"></div>
      <div class="nb b9" @click="showtotat"></div>
      <div class="nb b10" @click="showtotat"></div>


       <div class="nb b11" @click="show('wangguan','normal')"></div>
      <div class="nb b12" @click="showtotat"></div>
      <div class="nb b13" @click="showtotat"></div>

       <div class="nb b14" @click="show('daozhashebei','normal')"></div>
      <div class="nb b15" @click="showtotat"></div>
      <div class="nb b16" @click="showtotat"></div>
    </div>
  </div>
</template>

<script>
import bus from "@/lib/bus"
export default {
    mounted(){
    bus.$emit( 'set-sprite-display', 'bianpeidian', 'all', true)
    bus.$emit( 'set-sprite-display', 'daozhashebei', 'all', true)
    bus.$emit( 'set-sprite-display', 'wangguan', 'all', true)
    bus.$emit( 'set-sprite-display', 'dianzitinchepai', 'all', true)
    bus.$emit( 'set-sprite-display', 'bianpeidian', 'all', true)
    bus.$emit( 'set-sprite-display', 'zhaoming', 'all', true)
  },
  methods: {
       show(name1,name2){
               bus.$emit("sceneRunning1",true)
      bus.$emit( 'set-sprite-display', name1, name2, true)
    },
    showtotat(){
      bus.$emit("totat")
    }
  },
};
</script>
